@mixin mobile {
    @media (min-width: #{$screen-mobile-min}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$screen-tablet-min}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$screen-desktop-min}) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: #{$screen-large-desktop-min}) {
        @content;
    }
}

@mixin gradiant-background {
    background-color: #35096b;
    background-image: linear-gradient(225deg, rgba(255, 132, 0, 0), #ff9600),
    linear-gradient(45deg, rgba(247, 191, 19, 0), #f7bf13),
    linear-gradient(135deg, #f5c21b, #f7e5a9);
}
