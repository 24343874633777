// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$text-color: #333;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
//$font-size-base: 0.9rem;
//$line-height-base: 1.6;

// Colors
$main-color: #ff8400;
$secondary-color: #ffae02;
$button-color: #ff6100;
$button-active-color: #d85200;

// Bootstrap Custom Colors
$primary: #ff6100;
$success: #12B35C;

$min-contrast-ratio: 3;



//
//$blue: #3490dc;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66d9b;
//$red: #e3342f;
//$orange: #f6993f;
//$yellow: #ffed4a;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;

// Media
$screen-mobile-min: 576px;
$screen-tablet-min: 768px;
$screen-desktop-min: 992px;
$screen-large-desktop-min: 1200px;
