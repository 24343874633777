.header {
    position: relative;
    @include gradiant-background;

    &__navbar-brand {
        padding: 7px 0;
    }

    &__logo {
        max-height:50px;

        &_original {
            display: none;
        }
    }

    &__toggler {
        border: none;
        box-shadow: none!important;

        svg {
            color: white;
            width: 25px;
        }
    }

    & &__menu-item {
        position: relative;
        color: white;
        padding: 8px 0!important;
        font-weight: 400;
        transition: none;
        margin: 0 auto;
        font-size: 1.1em;

        @include desktop {
            margin: 0 15px;
            padding: 15px 0!important;
            font-size: 0.9375em;
        };

        @include tablet {
            margin: 0 14px;
        }

        &:after {
            position:absolute;
            left:0;
            bottom:0;
            width:100%;
            height:2px;
            content:"";
            background:#fff;
            transform:scale(0);
            transition: transform .5s ease;
        }

        &:hover, &.active {
            color: white;

            &:after {
                transform: scale(1);
            }
        }
    }

    .btn-outline-primary {
        background: white;
        border-color: transparent;
        color: $primary;
    }

    &_fixed {
        position: fixed;
        background: transparent;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;

        &.header_fixed-active, &.header_show-collapsed-menu {
            background: white;
            box-shadow: 0 1px 5px rgb(0 0 0 / 20%);

            .btn-outline-primary {
                border-color: $primary;
            }

            .header__menu-item {
                color: $text-color;

                &:after {
                    background: $text-color;
                }
            }

            .header__toggler {
                svg {
                    color: $text-color;
                }
            }

            .header__logo_white {
                display: none;
            }

            .header__logo_original {
                display: block;
            }
        }
    }
}
