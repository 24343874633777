.circle-icon {
    height:70px;
    width:70px;
    margin:0 auto;
    border-radius:50%;
    background-color: #35096b;
    background-image: linear-gradient(225deg, rgba(255, 132, 0, 0), #ff8400), linear-gradient(45deg, rgba(247, 191, 19, 0), #f7bf13), linear-gradient(135deg, #f5c21b, #f7e5a9);

    .fa, .ti {
        width:66px;
        line-height:66px;
        border-radius:50%;
        margin-top:2px;
        font-size:2.25em;
        color: $main-color;
        background:#fff;
        display:inline-block;
    }
}
