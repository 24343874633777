.article {
    $font-color: #333333 !default;
    $header-color: #222222 !default;
    $quiet-color: #999999 !default;
    $loud-color: #ff0000 !default;
    $link-color: #0066cc !default;
    $link-hover-color: #004999 !default;
    $link-active-color: #002266 !default;
    $link-visited-color: #663399 !default;
    $link-focus-color: #003366 !default;
    $alt-text-color: #555555 !default;
    $blueprint-table-header-color: #f5f5f5 !default;
    $blueprint-table-stripe-color: #f9f9f9 !default;
    $blueprint-background-color: #e0e0e0 !default;

    line-height: 1.5;
    margin: 6rem 0;
    font-weight: 400;

    h1, h2, h3, h4, h5, h6, img {
        font-weight: normal;
        color: $header-color;
        margin: 0;
    }
    h1 {
        font-size: 2em;
        line-height: 1;
        margin-bottom: 0.50em;
    }

    h2 {
        font-size: 1.5em;
        margin-bottom: 0.75em;
    }

    h3 {
        font-size: 1.2em;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 1.00em;
    }

    h4 {
        font-size: 1.1em;
        line-height: 1.25;
        font-weight: 600;
        margin-bottom: 1.25em;
    }

    h5 {
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 1.50em;
    }

    h6 {
        font-size: 1em;
        font-weight: 600;
    }

    p {
        margin: 0 0 1.5em;

        .left {
            float: left;
            margin: 1.5em 1.5em 1.5em 0;
            padding: 0;
        }

        .right {
            float: right;
            margin: 1.5em 0 1.5em 1.5em;
            padding: 0;
        }
    }

    blockquote {
        margin: 1.5em;
        color: $alt-text-color;
        font-style: italic;
    }

    strong, dfn {
        font-weight: 600;
    }

    em, dfn {
        font-style: italic;
    }

    sup, sub {
        line-height: 0;
    }

    abbr, acronym {
        border-bottom: 1px dotted #666666;
    }

    address {
        margin: 0 0 1.5em;
        font-style: italic;
    }

    del {
        color: $alt-text-color;
    }

    pre {
        margin: 1.5em 0;
        white-space: pre;
        font-size: 1em;
        line-height: 1.5;
    }

    code, tt {
        font-size: 1em;
        line-height: 1.5;
    }

    li ul, li ol {
        margin: 0;
    }

    ul, ol {
        margin: 0 1.5em 1.5em 0;
        padding-left: 1.5em;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    dl {
        margin: 0 0 1.5em 0;

        dt {
            font-weight: 600;
        }
    }

    dd {
        margin-left: 1.5em;
    }

    table {
        margin-bottom: 1.4em;
        width: 100%;

        th {
            font-weight: 600;
        }

        thead th {
            background: $blueprint-table-header-color;
        }

        th, td, caption {
            padding: 4px 10px 4px 5px;
        }

        &.striped tr:nth-child(even) td,
        tr.even td {
            background: $blueprint-table-stripe-color;
        }

        tfoot {
            font-style: italic;
        }

        caption {
            background: $blueprint-background-color;
        }
    }

    .quiet {
        color: $quiet-color;
    }

    .loud {
        color: $loud-color;
    }

    .embedded_image {
        img {
            width: 100%;
        }
    }

    img {
        width: 100%;
        margin: 0 0 1.5em;
    }
}
