// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto&display=swap');
@import "~font-awesome";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import './vendor/themify-icons/themify-icons.css';

// Variables
@import 'variables';

// Mixins
@import "mixins";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// app
@import "./main";
@import "./common";
@import "./header";
@import "./navigation";
@import "./article";
@import "./footer";

// components
@import "components/auth-card";
@import "components/button";
@import "components/notification";
@import "components/textarea";
@import "components/circle-icon";
