.notification {
    padding-left: 20px;
    border-left: solid transparent 3px;
    font-weight: 500;

    ul {
        margin-left: 0;
        padding: 0;
        list-style-type: '— ';
        list-style-position: inside;
    }

    &_success {
        color: $success;
        border-left-color: $success;
    }

    &_primary {
        color: $primary;
        border-left-color: $primary;
    }
}
