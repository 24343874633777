.footer {
    background:#404040;
    padding:60px 0;
    flex-shrink: 0;
    margin-top: auto;

    &__navigation {
        padding-bottom:10px;
        margin-bottom: 18px;

        li {
            margin:0 7px;
            font-size: .81em;
            display:inline-block;

            @include tablet {
                font-size: .91em;
                margin: 0 20px;
            }
        }

        a {
            color:#fff;

            &:hover {
                color: $main-color;
            }
        }
    }

    &__social-list {
        margin-bottom: 18px;

        li {
            font-size:1.1em;
            margin: 0 7px;
            display:inline-block;

            @include tablet {
                font-size:1.25em;
                margin:0 15px;
            }
        }

        a {
            color:#fff;

            &:hover {
                color: $main-color;
            }
        }
    }

    &__copyright-links {
        li {
            font-size:.7em;
            margin:0 7px;
            color:#979797;
            display: inline-block;

            @include tablet {
                font-size:.813em;
                margin:0 10px;
            }
        }

        a {
            color:#979797;

            &:hover {
                color: $main-color;
            }
        }
    }
}
