html {
    height: 100%;
    //scroll-behavior: initial!important;
}

body {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

ul {
    list-style-type: "— ";
    padding-left: 0;
    list-style-position: inside;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

label {
    font-weight: normal;
}

button {
    outline: none!important;
}

.text-thin {
    font-weight: 100;
}

.text-bold {
    font-weight: 500;
}
